/* You can add global styles to this file, and also import other style files */

//== Vendor
@import "../node_modules/ngx-bootstrap/datepicker/bs-datepicker.css";
@import "../node_modules/font-awesome/css/font-awesome.css";
@import "../node_modules/simple-line-icons/css/simple-line-icons.css";

@import "./styles/whirl.scss";
@import "../node_modules/spinkit/css/spinkit.css";
@import "../node_modules/loaders.css/loaders.css";

@import "../node_modules/angular2-toaster/toaster.css";

@import "../node_modules/ng2-dnd/style.css";
// @import '../node_modules/ika.jvectormap/jquery-jvectormap-1.2.2.css';

@import "../node_modules/summernote/dist/summernote.css";

//== Bootstrap
@import "./app/shared/styles/bootstrap.scss";
//== Application
@import "./app/shared/styles/app.scss";
@import "./app/shared/styles/app/header.scss";
@import "./app/shared/styles/app/sidebar.scss";
@import "./app/shared/styles/app/top-navbar.scss";
@import "@ng-select/ng-select/themes/default.theme.css";

/** Auth0 styles for login screen */
.auth0-lock.auth0-lock .auth0-lock-header-logo {
  height: 30px !important;
}
.auth0-lock.auth0-lock .auth0-lock-overlay {
  background: none !important;
}
.auth0-lock.auth0-lock .auth0-lock-header {
  padding: 21px 11px 11px 11px !important;
  height: 68px !important;
}
.auth0-lock.auth0-lock.auth0-lock-opened .auth0-lock-header-bg .auth0-lock-header-bg-blur {
  opacity: 0 !important;
}
@media (min-width: 481px) {
  .auth0-lock.auth0-lock .auth0-lock-header-bg {
    background: #fff !important;
  }
}

.automation-chart .panel-body {
  height: 148px;
  stacked-bar-chart {
    height: 120px !important;
    div {
      height: 120px !important;
    }
  }
}

.client-chart .panel-body {
  height: 148px;
}
.client-chart .panel-body .bar-chart {
  height: 120px !important;
}
.table th a.sorting i {
  position: relative;
  right: -5px;
  top: 0px;
  color: #888;
}
.template-legend-modal {
  width: 800px;
  margin: 30px auto;
  .content-heading {
    padding: 17px;
  }
  .content-data {
    margin-bottom: 30px;
  }
}
.campaigns-checkboxes-list {
  margin-top: 20px;
  max-height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
}
.bg-widget-class {
  background: #fff;
  color: #e88b1b;
  border: 1px solid #cfdbe2;
  border-top-width: 3px;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  a {
    color: #e88b1b !important;
  }
}
/** Nav buttons styling */
.content-heading {
  .btn-group {
    padding-left: 10px;
  }
  .btn {
    border-radius: 5px;
    border-color: #2f80e7;
  }
  .btn-primary {
    color: #2f80e7;
    background-color: #fff;
    margin-left: 10px;
  }
  .btn-primary:hover {
    color: #2f80e7;
    background-color: #fff;
    border-color: #2f80e7;
  }
  .btn-primary:active,
  .btn-primary.active,
  .open > .btn-primary.dropdown-toggle {
    color: #2f80e7;
    background-color: #fff;
    border-color: #2f80e7;
  }
  .btn-primary:active:hover,
  .btn-primary:active:focus,
  .btn-primary:active.focus,
  .btn-primary.active:hover,
  .btn-primary.active:focus,
  .btn-primary.active.focus,
  .open > .btn-primary.dropdown-toggle:hover,
  .open > .btn-primary.dropdown-toggle:focus,
  .open > .btn-primary.dropdown-toggle.focus {
    color: #fff;
    background-color: #2f80e7;
    border-color: #2f80e7;
  }
}
/** actions buttons styling */
form,
.action-button,
.content-heading {
  .btn-info {
    color: #fff;
    background-color: #2f80e7 !important;
    border-color: #2f80e7;
    margin-left: 10px;
  }

  .btn-info.disabled:hover,
  .btn-info.disabled:focus,
  .btn-info.disabled.focus,
  .btn-info[disabled]:hover,
  .btn-info[disabled]:focus,
  .btn-info[disabled].focus,
  fieldset[disabled] .btn-info:hover,
  fieldset[disabled] .btn-info:focus,
  fieldset[disabled] .btn-info.focus {
    background-color: #2f80e7;
    border-color: #2f80e7;
  }
  .btn-info:hover {
    color: #fff;
    background-color: #2f80e7;
    border-color: #2f80e7;
  }
}
table a,
.hyperlink-color a {
  color: #2f80e7 !important;
}
/** Actions Nav Styling */
table,
.actions-nav {
  .dropdown-menu > li > a:hover,
  .dropdown-menu > li > a:focus {
    text-decoration: none;
    color: #fff !important;
    background-color: #e88b1b;
  }
  .dropdown-menu > li > a {
    color: #263746 !important;
  }
}
/** Disable div elements */
.disabled-div {
  pointer-events: none;
  opacity: 0.5;
  fieldset {
    .form-group {
      opacity: 0.5;
    }
  }
}
.panel-body .table-responsive {
  overflow-x: inherit !important;
}
.filters-view {
  padding-left: 16px;
  padding-top: 6px;
  a {
    margin-right: 8px;
  }
}
.refresh-listing-icon {
  cursor: pointer;
}
.refresh-listing-top-icon {
  margin-right: 12px;
  cursor: pointer;
}
.breadcrumb .back-link {
  color: #929292;
  text-decoration: underline;
  cursor: pointer;
}
@media only screen and (min-width: 1601px) {
  bar-chart canvas {
    height: 100% !important;
  }
}
/*Close Icon for modal box*/
.modalcloseicon {
  margin-right: 10px;
  cursor: pointer;
  cursor: hand;
}
//For modal box heading
.modal-dialog .content-heading {
  padding: 17px;
}

.minsize {
  min-width: 100%;
  min-height: 100px;
}
label.required:after {
  content: " *";
  color: red;
}
.greyout {
  color: lightgrey;
}
.ml-12 {
  margin-left: 12px !important;
}
.mr-6 {
  margin-right: 6px !important;
}
.theme-green .bs-datepicker-head,
.theme-green .bs-datepicker-body table td span.selected {
  background-color: #e88b1b !important;
}

.toast-top-right {
  top: 60px !important;
}
.filter-accordion .panel-heading {
  padding: 0px !important;
  box-shadow: none !important;
}
.filter-accordion .panel,
.filter-accordion .panel-body,
.filter-accordion .panel-default {
  border: none !important;
  box-shadow: none !important;
}
.confirm-alert-title {
  font-size: 24px !important;
  float: left;
  margin-bottom: 10px;
}

.confirm-alert-body {
  font-size: 14px;
  float: left;
  padding-top: 12px;
  padding-bottom: 18px;
}
.swal2-html-container {
  border-bottom: 1px solid #e5e5e5;
  border-top: 1px solid #e5e5e5;
}
.mt-26 {
  margin-top: 26px !important;
}
.mr-12 {
  margin-right: 12px !important;
}
.mr-3 {
  margin-right: 3px !important;
}

.fixed-bottom {
  position: absolute !important;
  // bottom: 0px;
}

.list-group .list-group-item.active .list-group-item-text .onboard-page-link{
  color: white !important;
}